define("@adopted-ember-addons/ember-stripe-elements/components/stripe-element", ["exports", "@ember/component", "@ember/service", "@ember/object"], function (_exports, _component, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    classNames: ['ember-stripe-element'],
    autofocus: false,
    options: null,
    stripeElement: null,
    stripeError: null,
    type: null,
    // Set in components that extend from `stripe-element`

    stripev3: (0, _service.inject)(),
    elements: (0, _object.computed)({
      get() {
        return (0, _object.get)(this, 'stripev3.elements')();
      },
      set(key, value) {
        return value;
      }
    }),
    didInsertElement() {
      this._super(...arguments);

      // Fetch user options
      let options = (0, _object.get)(this, 'options') || {};

      // Fetch `type` set by child component
      let type = (0, _object.get)(this, 'type');

      // `stripeElement` instead of `element` to distinguish from `this.element`
      let stripeElement = (0, _object.get)(this, 'elements').create(type, options);

      // Mount the Stripe Element onto the mount point
      stripeElement.mount(this.element.querySelector('[role="mount-point"]'));

      // Make the element available to the component
      (0, _object.set)(this, 'stripeElement', stripeElement);
      this.stripev3.addStripeElement(stripeElement);

      // Set the event listeners
      this.setEventListeners();
    },
    didRender() {
      this._super(...arguments);
      // Fetch autofocus, set by user
      let autofocus = (0, _object.get)(this, 'autofocus');
      let stripeElement = (0, _object.get)(this, 'stripeElement');
      let iframe = this.element.querySelector('iframe');
      this._invokeAction('onLoad', stripeElement);
      if (autofocus && iframe) {
        iframe.onload = () => {
          stripeElement.focus();
        };
      }
    },
    didUpdateAttrs() {
      this._super(...arguments);
      let options = (0, _object.get)(this, 'options') || {};
      (0, _object.get)(this, 'stripeElement').update(options);
    },
    willDestroyElement() {
      this._super(...arguments);
      const stripeElement = (0, _object.get)(this, 'stripeElement');
      this.stripev3.removeStripeElement(stripeElement);
      stripeElement.unmount();
    },
    setEventListeners() {
      let stripeElement = (0, _object.get)(this, 'stripeElement');
      stripeElement.on('ready', event => {
        this._invokeAction('onReady', stripeElement, event);
      });
      stripeElement.on('blur', event => {
        this._invokeAction('onBlur', stripeElement, event);
      });
      stripeElement.on('focus', event => {
        this._invokeAction('onFocus', stripeElement, event);
      });
      stripeElement.on('change', (...args) => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        let [{
          complete,
          error: stripeError
        }] = args;
        this._invokeAction('onChange', stripeElement, ...args);
        if (complete) {
          this._invokeAction('onComplete', stripeElement);
        } else if (stripeError) {
          this._invokeAction('onError', stripeError);
        }
        (0, _object.set)(this, 'stripeError', stripeError);
      });
    },
    _invokeAction(method, ...args) {
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      if (typeof this[method] === 'function') {
        this[method](...args);
      }
    },
    onReady() {},
    onBlur() {},
    onFocus() {},
    onChange() {},
    onComplete() {},
    onError() {}
  });
});