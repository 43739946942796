define("ember-data-model-fragments/transforms/fragment", ["exports", "@ember/debug", "@ember/object", "@ember-data/serializer/transform", "@ember-data/serializer/json-api", "@ember/service"], function (_exports, _debug, _object, _transform, _jsonApi, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.fragment` fragment attribute which delegates work to
    the fragment type's serializer
  
    @class FragmentTransform
    @namespace MF
    @extends DS.Transform
  */
  const FragmentTransform = _transform.default.extend({
    store: (0, _service.inject)(),
    type: null,
    polymorphicTypeProp: null,
    deserialize: function deserializeFragment(data) {
      if (data == null) {
        return null;
      }
      return this.deserializeSingle(data);
    },
    serialize: function serializeFragment(snapshot) {
      if (!snapshot) {
        return null;
      }
      let store = this.store;
      let serializer = store.serializerFor(snapshot.modelName);
      return serializer.serialize(snapshot);
    },
    modelNameFor(data) {
      let modelName = this.type;
      let polymorphicTypeProp = this.polymorphicTypeProp;
      if (data && polymorphicTypeProp && data[polymorphicTypeProp]) {
        modelName = data[polymorphicTypeProp];
      }
      return modelName;
    },
    deserializeSingle(data) {
      let store = this.store;
      let modelName = this.modelNameFor(data);
      let serializer = store.serializerFor(modelName);
      (true && !(!(serializer instanceof _jsonApi.default)) && (0, _debug.assert)('The `JSONAPISerializer` is not suitable for model fragments, please use `JSONSerializer`', !(serializer instanceof _jsonApi.default)));
      let typeClass = store.modelFor(modelName);
      let serialized = serializer.normalize(typeClass, data);

      // `JSONSerializer#normalize` returns a full JSON API document, but we only
      // need the attributes hash
      return (0, _object.get)(serialized, 'data.attributes');
    }
  });
  var _default = _exports.default = FragmentTransform;
});