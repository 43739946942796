define("ember-data-model-fragments/transforms/fragment-array", ["exports", "ember-data-model-fragments/transforms/fragment"], function (_exports, _fragment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.fragmentArray` fragment attribute which delegates work to
    the fragment type's serializer
  
    @class FragmentArrayTransform
    @namespace MF
    @extends DS.Transform
  */
  const FragmentArrayTransform = _fragment.default.extend({
    deserialize: function deserializeFragmentArray(data) {
      if (data == null) {
        return null;
      }
      return data.map(datum => {
        return this.deserializeSingle(datum);
      }, this);
    },
    serialize: function serializeFragmentArray(snapshots) {
      if (!snapshots) {
        return null;
      }
      let store = this.store;
      return snapshots.map(snapshot => {
        let serializer = store.serializerFor(snapshot.modelName);
        return serializer.serialize(snapshot);
      });
    }
  });
  var _default = _exports.default = FragmentArrayTransform;
});