define("ember-file-upload/helpers/file-queue", ["exports", "@ember/component/helper", "@ember/service"], function (_exports, _helper, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  /**
    `file-queue` helper is one of the core primitives of ember-file-upload.
    It has three different flavors of invocation. The first is
    a bare call, which will return the master queue:
  
    ```hbs
    {{#with (file-queue) as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    If called with a `name` property, it will return the queue
    of that name:
  
    ```hbs
    {{#with (file-queue name="photos") as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    @class FileQueue
    @param {string} [name] The name of the queue to get information on
    @return {Queue} A collection of all queues, or a specific queue.
   */
  let FileQueueHelper = _exports.default = (_dec = (0, _service.inject)('file-queue'), _class = class FileQueueHelper extends _helper.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "fileQueue", _descriptor, this);
    }
    compute(_, hash) {
      let queues = this.fileQueue;
      let queueName = hash['name'];
      if (queueName) {
        let queue = queues.find(queueName) || queues.create(queueName);
        return queue;
      }
      return queues;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "fileQueue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});