define("ember-data-model-fragments/transforms/array", ["exports", "@ember/debug", "@ember/application", "@ember/array", "@ember/object", "@ember-data/serializer/transform", "@ember/service"], function (_exports, _debug, _application, _array, _object, _transform, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    @module ember-data-model-fragments
  */

  /**
    Transform for `MF.array` that transforms array data with the given transform
    type.
  
    @class ArrayTransform
    @namespace MF
    @extends DS.Transform
  */
  const ArrayTransform = _transform.default.extend({
    store: (0, _service.inject)(),
    type: null,
    deserialize: function deserializeArray(data) {
      if (data == null) {
        return null;
      }
      let transform = this.transform;
      data = (0, _array.makeArray)(data);
      if (!transform) {
        return data;
      }
      return data.map(transform.deserialize, transform);
    },
    serialize: function serializeArray(array) {
      if (array == null) {
        return null;
      }
      let transform = this.transform;
      array = array.toArray ? array.toArray() : array;
      if (!transform) {
        return array;
      }
      return array.map(transform.serialize, transform);
    },
    transform: (0, _object.computed)('type', function () {
      let attributeType = this.type;
      if (!attributeType) {
        return null;
      }
      let transform = (0, _application.getOwner)(this).lookup(`transform:${attributeType}`);
      (true && !(!!transform) && (0, _debug.assert)(`Unable to find transform for '${attributeType}'`, !!transform));
      return transform;
    })
  });
  var _default = _exports.default = ArrayTransform;
});