define("ember-popper/components/ember-popper", ["exports", "ember-popper/components/ember-popper-base", "@ember/object/internals"], function (_exports, _emberPopperBase, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberPopperBase.default.extend({
    /**
     * The element the popper will target.
     * @argument
     * @type(Element)
     */
    popperTarget: null,
    // ================== LIFECYCLE HOOKS ==================

    init() {
      this.id = this.id || `${(0, _internals.guidFor)(this)}-popper`;
      this._super(...arguments);
    }
  });
});