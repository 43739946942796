define("ember-attacher/templates/components/attach-popover", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "tjZNSVHP",
    "block": "[[[1,[28,[35,0],[[30,0,[\"_parentFinder\"]]],null]],[41,[28,[37,2],[[30,0,[\"_currentTarget\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"lazyRender\"]]],null],[30,0,[\"_mustRender\"]]],null]],null],[[[6,[39,5],null,[[\"ariaRole\",\"class\",\"eventsEnabled\",\"id\",\"modifiers\",\"placement\",\"popperContainer\",\"popperTarget\",\"registerAPI\",\"renderInPlace\"],[[30,0,[\"ariaRole\"]],\"ember-attacher\",false,[30,0,[\"id\"]],[30,0,[\"_modifiers\"]],[30,0,[\"placement\"]],[30,0,[\"popperContainer\"]],[30,0,[\"_currentTarget\"]],[28,[37,6],[[30,0],\"_registerAPI\"],null],[30,0,[\"renderInPlace\"]]]],[[\"default\"],[[[[10,0],[15,0,[29,[[30,0,[\"_class\"]]]]],[15,5,[30,0,[\"_style\"]]],[12],[1,\"\\n      \"],[18,2,[[28,[37,8],null,[[\"emberPopper\",\"hide\"],[[30,1],[28,[37,6],[[30,0],\"hide\"],null]]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"arrow\"]],[[[1,\"        \"],[10,0],[14,\"x-arrow\",\"\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,9],[[30,0,[\"animation\"]],\"fill\"],null],[[[1,\"        \"],[10,0],[14,\"x-circle\",\"\"],[15,5,[30,0,[\"_circleTransitionDuration\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13]],[1]]]]]],[]],null]],[\"emberPopper\",\"&default\"],false,[\"unbound\",\"if\",\"and\",\"or\",\"not\",\"ember-popper\",\"action\",\"yield\",\"hash\",\"eq\"]]",
    "moduleName": "ember-attacher/templates/components/attach-popover.hbs",
    "isStrictMode": false
  });
});