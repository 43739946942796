define("ember-attacher/-debug/helpers", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assert = assert;
  _exports.debug = debug;
  _exports.debugOnError = debugOnError;
  _exports.stripInProduction = stripInProduction;
  const {
    Logger
  } = _ember.default;
  function assert(msg, conditional) {
    if (!conditional) {
      throw new Error(msg);
    }
  }
  function debug() {
    Logger.debug(...arguments);
  }
  function debugOnError(msg, conditional) {
    if (!conditional) {
      console.error(msg); // eslint-disable-line no-console
      debugger; // eslint-disable-line no-debugger
    }
  }
  function stripInProduction(cb) {
    cb();
  }
});