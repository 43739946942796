define("ember-attacher/components/attach-tooltip", ["exports", "@ember-decorators/object", "@ember/object", "ember-attacher/components/attach-popover", "ember-attacher/defaults"], function (_exports, _object, _object2, _attachPopover, _defaults) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _class2;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const classic = __EMBER_CLASSIC_DECORATOR;
  let AttachTooltip = _exports.default = (_dec = (0, _object2.computed)('_config.tooltipClass'), _dec2 = (0, _object.observes)('popperTarget'), classic(_class = (_class2 = class AttachTooltip extends _attachPopover.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "configKey", 'tooltip');
      _defineProperty(this, "ariaRole", 'tooltip');
    }
    get class() {
      return this._config.tooltipClass || _defaults.default.tooltipClass;
    }
    set class(value) {
      const tooltipClass = this._config.tooltipClass || _defaults.default.tooltipClass;

      // eslint-disable-next-line no-setter-return
      return `${tooltipClass} ${value}`;
    }
    didInsertElement() {
      super.didInsertElement(...arguments);
      if (!this._currentTarget) {
        return;
      }
      this._currentTarget.setAttribute('aria-describedby', this.id);
    }
    popperTargetChanged() {
      const oldTarget = this._currentTarget;
      if (oldTarget) {
        oldTarget.removeAttribute('aria-describedby');
      }
      super.popperTargetChanged;
      this.popperTarget.setAttribute('aria-describedby', this.id);
    }
    willDestroyElement() {
      super.willDestroyElement(...arguments);
      const target = this._currentTarget;
      if (target) {
        target.removeAttribute('aria-describedby');
      }
    }
  }, _applyDecoratedDescriptor(_class2.prototype, "class", [_dec], Object.getOwnPropertyDescriptor(_class2.prototype, "class"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "popperTargetChanged", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "popperTargetChanged"), _class2.prototype), _class2)) || _class);
});